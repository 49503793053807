@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

html {
    overflow-x: hidden;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Poppins", sans-serif;
}

.fadeIn {
    animation-name: fadeInKeyframes;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

@keyframes fadeInKeyframes {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.extend {
    animation-name: extendKeyframes;
    animation-duration: 200ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
}

@keyframes extendKeyframes {
    0% {
        height: 0px;
    }

    100% {
        height: 6rem;
    }
}

.slideRight {
    animation-name: slideRightKeyframes;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
}

@keyframes slideRightKeyframes {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.slideLeft {
    animation-name: slideLeftKeyframes;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
}

@keyframes slideLeftKeyframes {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.popOut {
    animation-name: pop;
    animation-duration: 1000ms;
    animation-fill-mode: forwards;
}

@keyframes pop {
    50% {
        transform: scale(1);
    }

    60% {
        transform: scale(1.2);
        background-color: inherit;
    }

    65% {
        transform: scale(1);
        background-color: transparent;
    }

    100% {
        transform: scale(1);
        background-color: transparent;
    }
}

.popIn {
    animation-name: popInKeyframes;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
}

@keyframes popInKeyframes {
    0% {
        opacity: 0;
        transform: rotate(5deg) scale(1);
    }
    49% {
        opacity: 0;
        transform: none;
        transform: rotate(5deg) scale(1);
    }
    50% {
        opacity: 1;
        transform: rotate(5deg) scale(1);
    }
    55% {
        opacity: 1;
        transform: rotate(5deg) scale(1.05);
    }
    60% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
}

.popInInstant {
    animation-name: popInInstantKeyframes;
    animation-duration: 2000ms;
    animation-fill-mode: forwards;
}

@keyframes popInInstantKeyframes {
    0% {
        transform: none;
        transform: rotate(5deg) scale(1);
    }
    1% {
        transform: rotate(5deg) scale(1);
    }
    6% {
        opacity: 1;
        transform: rotate(5deg) scale(1.05);
    }
    11% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
    100% {
        opacity: 1;
        transform: rotate(0deg) scale(1);
    }
}

.text-stroke {
    -webkit-text-stroke: 3px black;
    text-stroke: 3px black;
}

.preserve {
    transform-style: preserve-3d;
}
